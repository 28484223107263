import * as React from "react";
import { SvgIcon, useTheme, SvgIconProps } from "@mui/material";

export default function GatsbyIcon(props: SvgIconProps) {
  let color = "#000";
  const theme = useTheme();
  color = theme.palette.primary.main;
  return (
    <SvgIcon {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SVGIcon" fill={color} fillRule="nonzero">
          <g id="gatsby">
            <path d="M12,0 C5.31965726,0 0,5.31865933 0,11.9977489 C0,18.6768384 5.31965726,24 12,24 C18.6803427,24 24,18.6775888 24,11.9977489 C24,5.31790895 18.6803427,0 12,0 L12,0 Z M2.606542,12.1028014 L11.8979298,21.3894447 C6.78166239,21.3894447 2.606542,17.2151076 2.606542,12.1028014 Z M14.0871849,21.1823412 L2.81743699,9.91095548 C3.75633248,5.73661831 7.51266496,2.60605303 12,2.60605303 C15.0103196,2.61430715 17.8360123,4.04452226 19.6184877,6.46823412 L18.2623053,7.61705853 C16.8273188,5.51525763 14.4429295,4.26438219 11.8979298,4.27563782 C8.64594409,4.25687844 5.74595034,6.31590795 4.69372694,9.39244622 L14.5052223,19.2013507 C16.9046219,18.3646823 18.785415,16.2778889 19.3055225,13.7723862 L15.2354744,13.7723862 L15.2354744,11.9984992 L21.3927075,11.9984992 C21.3927075,16.4849925 18.2615548,20.2443722 14.0864344,21.1830915 L14.0871849,21.1823412 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
