import * as React from "react";
import { useTheme, SvgIcon, SvgIconProps } from "@mui/material";

export default function GraphqlIcon(props: SvgIconProps) {
  const theme = useTheme();
  const color1 = theme.palette.primary.main;

  return (
    <SvgIcon {...props}>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="NewSVGIcon">
          <rect fill="none" x="0" y="0" width="24" height="24"></rect>
          <g
            id="GraphQL_Logo"
            transform="translate(2, 1)"
            fill={color1}
            fillRule="nonzero"
          >
            <polygon
              id="Rectangle"
              transform="translate(5.6404, 8.7265) rotate(-149.9993) translate(-5.6404, -8.7265)"
              points="5.17410529 -0.270467294 6.10666904 -0.270467294 6.10666904 17.7235188 5.17410529 17.7235188"
            ></polygon>
            <rect id="Rectangle" x="1" y="15" width="17" height="1"></rect>
            <polygon
              id="Rectangle"
              transform="translate(5.6425, 17.7584) rotate(-149.9993) translate(-5.6425, -17.7584)"
              points="0.446012725 17.2921428 10.8390425 17.2921428 10.8390425 18.2247065 0.446012725 18.2247065"
            ></polygon>
            <polygon
              id="Rectangle"
              transform="translate(13.4613, 4.2147) rotate(-149.9993) translate(-13.4613, -4.2147)"
              points="8.26479924 3.74837873 18.657829 3.74837873 18.657829 4.68094248 8.26479924 4.68094248"
            ></polygon>
            <polygon
              id="Rectangle"
              transform="translate(5.645, 4.2111) rotate(-120.0007) translate(-5.645, -4.2111)"
              points="5.17874012 -0.98540484 6.11130387 -0.98540484 6.11130387 9.40762493 5.17874012 9.40762493"
            ></polygon>
            <polygon
              id="Rectangle"
              transform="translate(13.4677, 8.7267) rotate(-120.0007) translate(-13.4677, -8.7267)"
              points="4.47071993 8.26041244 22.4647061 8.26041244 22.4647061 9.19297619 4.47071993 9.19297619"
            ></polygon>
            <rect id="Rectangle" x="1" y="6" width="1" height="10"></rect>
            <rect id="Rectangle" x="17" y="6" width="1" height="10"></rect>
            <polygon
              id="Rectangle"
              transform="translate(13.4613, 17.7532) rotate(-120.0007) translate(-13.4613, -17.7532)"
              points="13.0539804 13.2336758 13.8685692 13.2336758 13.8685692 22.2728027 13.0539804 22.2728027"
            ></polygon>
            <path
              d="M18.7294839,16.9997116 C18.1800209,17.9564557 16.9551763,18.2830091 15.9993396,17.7330245 C15.043503,17.1830398 14.7172593,15.9570325 15.2667223,15.0002884 C15.8161853,14.0435443 17.0410299,13.7169909 17.9968666,14.2669755 C18.9584268,14.8226892 19.2846705,16.0429675 18.7294839,16.9997116"
              id="Path"
            ></path>
            <path
              d="M3.73302447,6.99971163 C3.18303984,7.95645572 1.95703245,8.28300909 1.00028837,7.73302447 C0.0435442793,7.18303984 -0.283009091,5.95703245 0.266975533,5.00028837 C0.816960157,4.04354428 2.04296755,3.71699091 2.99971163,4.26697553 C3.95645572,4.82268916 4.28300909,6.04296755 3.73302447,6.99971163"
              id="Path"
            ></path>
            <path
              d="M0.266975533,16.9997116 C-0.283009091,16.0429675 0.0435442793,14.8226892 1.00028837,14.2669755 C1.95703245,13.7169909 3.17731084,14.0435443 3.73302447,15.0002884 C4.28300909,15.9570325 3.95645572,17.1773108 2.99971163,17.7330245 C2.03723854,18.2830091 0.816960157,17.9564557 0.266975533,16.9997116"
              id="Path"
            ></path>
            <path
              d="M15.2669755,6.99971163 C14.7169909,6.04296755 15.0435443,4.82268916 16.0002884,4.26697553 C16.9570325,3.71699091 18.1773108,4.04354428 18.7330245,5.00028837 C19.2830091,5.95703245 18.9564557,7.17731084 17.9997116,7.73302447 C17.0429675,8.28300909 15.8169602,7.95645572 15.2669755,6.99971163"
              id="Path"
            ></path>
            <path
              d="M10,22 C8.89398281,22 8,21.1060172 8,20 C8,18.8939828 8.89398281,18 10,18 C11.1060172,18 12,18.8939828 12,20 C12,21.1002865 11.1060172,22 10,22"
              id="Path"
            ></path>
            <path
              d="M10,4 C8.89398281,4 8,3.10601719 8,2 C8,0.893982808 8.89398281,0 10,0 C11.1060172,0 12,0.893982808 12,2 C12,3.10601719 11.1060172,4 10,4"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
