import * as React from "react";
import { useTheme, SvgIcon, SvgIconProps } from "@mui/material";

export default function GitlabIcon(props: SvgIconProps) {
  const theme = useTheme();
  const color1 = theme.palette.primary.main;
  return (
    <SvgIcon {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect fill="none" x="0" y="0" width="24" height="24"></rect>
          <path
            d="M21.5988851,9.98404214 L21.5707679,9.91315636 L18.8486026,2.90471084 C18.7932181,2.76734887 18.6951508,2.65082416 18.5684716,2.57185591 C18.3088364,2.41285623 17.9762312,2.43049258 17.7353682,2.6160311 C17.6190798,2.70921518 17.5347196,2.83548316 17.4937682,2.97765127 L15.6557339,8.52523348 L8.2129966,8.52523348 L6.37496227,2.97765127 C6.33509913,2.83470872 6.2505565,2.70780816 6.13336229,2.61500377 C5.89249932,2.42946525 5.55989407,2.4118289 5.30025891,2.57082858 C5.17386397,2.65011569 5.07588236,2.76653858 5.0201279,2.90368351 L2.29275572,9.90904704 L2.26567986,9.97993281 C1.46064269,12.0549896 2.14433283,14.4051493 3.94230041,15.7432543 L3.95167282,15.7504456 L3.97666592,15.7679103 L8.12343798,18.8314084 L10.1749551,20.3631575 L11.4246101,21.2939185 C11.725017,21.5189278 12.1405894,21.5189278 12.4409962,21.2939185 L13.6906513,20.3631575 L15.7421684,18.8314084 L19.9139335,15.7494183 L19.9243473,15.7411997 C21.7181096,14.4029744 22.400485,12.0569284 21.5988851,9.98404214 Z"
            fill={color1}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}
